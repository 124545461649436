<template>
  <main>
    <package-header title="Title Searches" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2">
          <!-- Package navigation hamburger -->
          <button class="btn btn-secondary d-lg-none mb-2" data-toggle="collapse" data-target="#package-nav" aria-expanded="false" aria-controls="package-nav">
            <svg class="icon icon-menu"><use xlink:href="/icons/symbol-defs.svg#icon-menu"></use></svg>
            <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
            Navigate Package
          </button>

          <div class="collapse collapse-lg" id="package-nav">
            <package-navigation></package-navigation>
          </div>
        </div>

        <div class="col">
          <!-- Tabs navigation -->
          <ul class="nav nav-tabs mb-4" role="tablist">
            <li class="nav-item">
              <router-link to="/pages/title-searches/purchase" class="nav-link"> Purchase </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/pages/title-searches/manage" class="nav-link active"> Manage Purchases </router-link>
            </li>
          </ul>

          <!-- Main package content -->
          <div class="row">
            <!-- Data entry form -->
            <div class="col">
              <div class="d-lg-flex justify-content-between align-items-center mb-1">
                <h4 class="mb-0">View existing orders for this package, or cancel a pending order.</h4>
                <div class="d-flex align-items-baseline small">
                  <span>Don't see your order?</span>
                  <button class="btn btn-link btn-sm">
                    <svg class="icon"><use xlink:href="/icons/symbol-defs.svg#icon-refresh"></use></svg>
                    Refresh list
                  </button>
                </div>
              </div>

              <table class="table table-stacked table-select-row mb-4">
                <thead class="bg-light">
                  <tr>
                    <th scope="col">PID</th>
                    <th scope="col">Short Legal Description</th>
                    <th scope="col">Title</th>
                    <th scope="col">Issue Time</th>
                    <th scope="col">Processed Fees</th>
                    <th scope="col">Pending Fees</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in tableItems" :key="index">
                    <td data-header="PID">
                      <span class="td-content text-nowrap">{{ item.a }}</span>
                    </td>
                    <td data-header="Short Legal Description">
                      <span class="td-content">{{ item.b }}</span>
                    </td>
                    <td data-header="Title">
                      <span class="td-content" v-html="item.c"></span>
                    </td>
                    <td data-header="Issue Time">
                      <span class="td-content">{{ item.d }}</span>
                    </td>
                    <td data-header="Processed Fees">
                      <span class="td-content">{{ item.e }}</span>
                    </td>
                    <td data-header="Pending Fees">
                      <span class="td-content">{{ item.f }}</span>
                    </td>
                    <td data-header="Actions">
                      <span class="td-content" v-html="item.g"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Searches &amp; STCs"></help-panel>
  </main>
</template>

<script>
import PackageHeader from "../../../components/PackageHeader.vue";
import PackageNavigation from "../../../components/PackageNavigation.vue";
import HelpPanel from "../../../components/HelpPanel.vue";

export default {
  components: {
    "package-header": PackageHeader,
    "package-navigation": PackageNavigation,
    "help-panel": HelpPanel,
  },

  data() {
    return {
      multipleItemsSelected: false,
      postSearch: false,
      tableItems: [
        {
          a: "015-671-569",
          b: "S/1592///A//8",
          c: "KT119399 NE",
          d: "# hours after submission",
          e: "",
          f: "$12.82",
          g: "<a href='javascript:void(0)' class='text-danger'>Cancel</button>",
        },
        {
          a: "015-671-570",
          b: "S/1592///A//12",
          c: "<a href='javascript:void(0)'>KT119321 NE</a>",
          d: "Sep 21 2017 2:41 PM",
          e: "$12.82",
          f: "",
        },
      ],
    };
  },
};
</script>
